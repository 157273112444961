export default {
    /**
     * 计算数组某个值的总和
     * @param arr 需要计算的数组
     * @param data 值的名称, 不传的话arr需要传纯数字数组
     * @returns {number} 返回计算后的总和
     */
    sum(arr,data=''){
        let total=0;
        let newArr=[];
        if(data!=='') newArr=arr.map(item=>item[data]?item[data]:'');
        else newArr=arr;
        for (let i=newArr.length-1; i>=0; i--) {
            if(newArr[i]) total += newArr[i];
        }
        return total;
    },
    /**
     * 计算对象下所有值的数字总和
     * @param obj
     * @returns {number}
     */
    sumObj(obj){
        let arr=[];
        let total=0;
        for(let i in obj){
            if(obj[i]) arr.push(Number(obj[i]))
        }
        total = this.sum(arr);
        return total;
    },
    /**
     * 填报月度基准时间计算
     * @param day 传入基准日期
     * @returns {object} 返回当前需要填报的月度 ,{year:允许填报年度,month:允许填报月度"}
     */
    com_current_month(day){
        let now=new Date();
        let year=now.getFullYear();//当前年份
        let month=now.getMonth()+1;//当前月度
        let base_time=new Date(`${year}-${month}-${day} 00:00:00`);//基准时间,如5月11日00点前修改4月及4月以后的月报并且选择时间只能为4月至4月以后
        //当前时间大于基准时间, 只允许上报当前月的月报,否则可以上报上月月报
        if(now>base_time){
            return {year, month};
        }else{
            //如果当前为1月,则返回上一年的12月
            if(month ===1 ){
                return {year:year-1, month:12};
            }else{
                return {year, month:month-1};
            }
        }
    }
}