<template>
    <div class="project_detail">
        <div @click="projectDetail">查看</div>
        <el-dialog title="项目详情" :visible.sync="show" :close-on-click-modal="false" width="1105px" append-to-body>
            <div class="detail">
                <div class="content">
                    <div class="detail_item items">
                        <div class="item long">
                            <div class="left">项目名称</div>
                            <div class="right">{{detail.project_name}}</div>
                        </div>
                        <div class="item">
                            <div class="left">总投资金额</div>
                            <div class="right"><span v-if="detail.all_invest_num">{{detail.all_invest_num}}万元</span></div>
                        </div>
                        <div class="item" v-if="detail.start_time">
                            <div class="left">建设周期</div>
                            <div class="right">{{detail.start_time.slice(0,4)}}年{{detail.start_time.slice(5,7)}}月-{{detail.end_time.slice(0,4)}}年{{detail.end_time.slice(5,7)}}月</div>
                        </div>
                        <!--<div class="item">
                            <div class="left">所属年度</div>
                            <div class="right">{{new Date().getFullYear()}}年</div>
                        </div>-->
                        <div class="item" v-for="(year,index) in years" :key="index">
                            <div class="left">{{year}}年度投资金额</div>
                            <div class="right">{{detail.year_invest_num[year]}}万元</div>
                        </div>
                        <div class="item long">
                            <div class="left">建设性质</div>
                            <div class="right">{{initBuildQuality(detail.build_quality)}}</div>
                        </div>
                        <div class="item">
                            <div class="left">项目性质</div>
                            <div class="right">{{initProjectQuality(detail.project_quality)}}</div>
                        </div>
                        <div class="item">
                            <div class="left">项目类别</div>
                            <div class="right">{{initProjectGenre(detail.project_genre)}}</div>
                        </div>
                        <div class="item long">
                            <div class="left">项目审核</div>
                            <div class="right">{{initProjectGrade(detail)}}</div>
                        </div>
                        <div class="item long">
                            <div class="left">项目概况</div>
                            <div class="right" v-html="detail.new_project_profile"></div>
                        </div>
                        <div class="item">
                            <div class="left">联络小组</div>
                            <div class="right">{{detail.contact_group?detail.contact_group.name:''}}</div>
                        </div>
                        <div class="item">
                            <div class="left">联络小组负责人</div>
                            <div class="right">{{detail.contact_group_fuze?detail.contact_group_fuze.nickname:''}}</div>
                        </div>
                        <div class="item">
                            <div class="left">项目负责单位</div>
                            <div class="right">{{detail.project_unit}}</div>
                        </div>
                        <div class="item">
                            <div class="left">项目负责人</div>
                            <div class="right">{{detail.project_fuze}}</div>
                        </div>
                        <div class="item long">
                            <div class="left">负责人电话</div>
                            <div class="right">{{detail.fuze_phone}}</div>
                        </div>
                        <div class="item long">
                            <div class="left">项目审计情况</div>
                            <div class="right">{{detail.audit||''}}</div>
                        </div>
                        <div class="item long">
                            <div class="left">项目检查问题</div>
                            <div class="right">{{detail.check||''}}</div>
                        </div>
                        <div class="item long">
                            <div class="left">项目评估内容</div>
                            <div class="right">{{detail.assess||''}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import api from '@/api/methods/index';
    import computedData from '@/mixins/computedData.js';
    export default {
        mixins:[computedData],
        props:['project_id'],
        data(){
            return{
                show:false, years:[], detail:{},
            }
        },
        methods:{
            projectDetail(){
                this.detail={};
                this.years=[];
                this.show=true;
                api.reserve.reserveProjectDetail({project_id:this.project_id}).then(res=>{
                    if(res.data){
                        let detail=res.data.rows||{};
                        if(!detail.contact_group) detail.contact_group={};
                        if(!detail.contact_group_fuze) detail.contact_group_fuze={};
                        if(detail.all_invest_num){
                            detail.all_invest_num=Number(detail.all_invest_num)/10000;
                        }
                        if(detail.project_brfore_num!==''&&detail.project_brfore_num!==undefined&&detail.project_brfore_num!==null){
                            detail.project_brfore_num=Number(detail.project_brfore_num)/10000;
                        }else{
                            detail.project_brfore_num='';
                        }
                        if(detail.year_invest_num){
                            detail.year_invest_num=JSON.parse(detail.year_invest_num);
                            for(let year in detail.year_invest_num){
                                detail.year_invest_num[year]=Number(detail.year_invest_num[year])/10000
                            }
                        }else{
                            detail.year_invest_num={};
                        }
                        //项目概况
                        if(detail.project_profile){
                            let regN = /\n/g;
                            let regR = /\r/g;
                            detail.new_project_profile=detail.project_profile.replace(regN, "<br />").replace(regR, "<br />");
                        }
                        this.detail=detail;
                        if(detail.start_time&&detail.end_time){
                            let start=detail.start_time.slice(0,4);
                            let end=detail.end_time.slice(0,4);
                            for(let i=start;i<=end;i++){
                                this.years.push(Number(i));
                            }
                        }
                    }
                })
            },
        },
    }
</script>

<style scoped>

</style>