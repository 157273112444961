<template>
    <el-dialog title="项目审核" :visible.sync="show" width="500px" custom-class="reserve_audit_dialog"
               :close-on-press-escape="false" append-to-body>
        <div class="content_box">
            <div class="radio_group">
                <div :class="{radio:true,active:project_grade===item.value}" v-for="item in projectGrade"
                     :key="item.value" @click="project_grade=item.value">
                    <div class="icon">
                        <img class="default" src="../../assets/img/radio.png" alt="">
                        <img class="active" src="../../assets/img/radio_active.png" alt="">
                    </div>
                    <div class="label">{{ item.label }}</div>
                </div>
            </div>
            <div class="input" v-show="project_grade===2||project_grade===3">
                <div class="label">审核意见：</div>
                <el-input v-model="project_grade_reason" placeholder="请输入审核意见"></el-input>
            </div>
            <div class="group_checkbox" v-show="project_grade===4">
                <div class="check_box" :class="{active:project_grade_choose.indexOf(v.value)!==-1}"
                     v-for="(v,k) in projectGradeChoose" :key="k" @click="chooseCheckBox(v)">
                    <div class="icon">
                        <img class="default" src="../../assets/img/checkbox.png" alt="">
                        <img class="active" src="../../assets/img/checkbox_active.png" alt="">
                    </div>
                    <div class="text">{{ v.label }}</div>
                </div>
            </div>
        </div>
        <div class="button">
            <el-button class="button_88px" size="small">取消</el-button>
            <el-button class="button_88px" size="small" type="primary" @click="sure">确定</el-button>
        </div>
    </el-dialog>
</template>

<script>
import api from '@/api/methods/index';

export default {
    name: "reserve_audit",
    data() {
        return {
            show: false,
            project_id: '',//项目id
            project_grade: 2,
            projectGrade: [
                {value: 2, label: '不予支持'},
                {value: 3, label: '进一步完善'},
                {value: 4, label: '开展前期工作'},
            ],
            project_grade_reason: '',//不予支持或进一步完善的意见
            project_grade_choose: [],//开展前期工作的选项（ 1：可研报告评审 2：地勘 3：环境评测）
            projectGradeChoose: [
                {value: 1, label: '可研报告评审'},
                {value: 2, label: '地勘'},
                {value: 3, label: '环境评测'},
            ]
        };
    },
    methods: {
        //多选
        chooseCheckBox(v) {
            let index = this.project_grade_choose.indexOf(v.value);
            if (index === -1) {
                this.project_grade_choose.push(v.value);
            } else {
                this.project_grade_choose.splice(index, 1);
            }
        },
        sure() {
            let param = {
                project_id: this.project_id,
                project_grade: this.project_grade
            };
            if (this.project_grade === 2 || this.project_grade === 3) {
                if (!this.project_grade_reason) {
                    this.$message({message: '请填写审核意见!', type: 'warning'});
                    return;
                } else {
                    param.project_grade_reason = this.project_grade_reason;
                }
            }
            if (this.project_grade === 4) {
                if (this.project_grade_choose.length <= 0) {
                    this.$message({message: '至少选择一项!', type: 'warning'});
                    return;
                } else {
                    param.project_grade_choose = this.project_grade_choose.join(',');
                }
            }
            api.reserve.reserveprojectUpgrade(param).then(res => {
                if (res.data.state == 1) {
                    this.$message({
                        message: '项目审核成功',
                        type: 'success'
                    });
                    this.show = false;
                    this.$emit('initList');
                }
            });
        }
    },
    watch: {
        show(n, o) {
            if (n) {
                this.project_grade = 2;
                this.project_grade_reason = '';
                this.project_grade_choose = [];
            }
        }
    }
};
</script>

<style lang="less">
.reserve_audit_dialog {
    .content_box {
        padding: 0 20px;

        .radio_group {
            padding: 20px 0;

            .radio:first-of-type {
                margin-left: 0 !important;
            }
        }

        .input {
            padding-bottom: 20px;
            display: flex;
            align-items: center;

            .label {
                flex-shrink: 0;
            }
        }

        .group_checkbox {
            display: flex;
            padding: 11px 0 30px 0;

            .check_box {
                display: flex;
                align-items: center;
                cursor: pointer;
                user-select: none;
                margin-right: 40px;

                .icon {
                    width: 16px;
                    flex-shrink: 0;
                    height: 16px;
                    position: relative;
                    margin-right: 5px;

                    img {
                        position: absolute;
                        left: 0;
                        top: 0;
                        right: 0;
                        transition: opacity 0.3s;

                        &.default {
                            opacity: 1;
                        }

                        &.active {
                            opacity: 0;
                        }
                    }
                }

                .text {
                    flex: 1;
                    min-width: 0;
                    text-align: left;
                    color: #999;
                    transition: color 0.3s;
                }

                &.active {
                    .text {
                        color: #3396fb;
                    }

                    img {
                        &.default {
                            opacity: 0;
                        }

                        &.active {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
}
</style>