<template>
    <div class="reserve main">
        <!--<ul class="tabs">
            <li>储备项目</li>
        </ul>-->
        <div class="container">
            <div class="filter">
                <div class="search">
                    <div class="item">
                        <div class="left">所属年度</div>
                        <el-date-picker size="medium" v-model="year" type="year" value-format="yyyy" placeholder="项目年度"
                                        @change="search"></el-date-picker>
                    </div>
                    <div class="item">
                        <div class="left">所属地区</div>
                        <el-select size="medium" v-model="area" popper-class="global_select_popper"
                                   @change="search" :disabled="chubei_project_power.area>0">
                            <el-option v-for="item in areaList" :key="item.value" :label="item.label"
                                       :value="item.value"></el-option>
                        </el-select>
                    </div>
                    <div class="item">
                        <div class="left">项目性质</div>
                        <el-select size="medium" v-model="project_quality" popper-class="global_select_popper"
                                   @change="search">
                            <el-option v-for="item in projectQuality" :key="item.value" :label="item.label"
                                       :value="item.value"></el-option>
                        </el-select>
                    </div>
                    <div class="item">
                        <div class="left">项目类别</div>
                        <el-select size="medium" v-model="project_genre" popper-class="global_select_popper"
                                   @change="search">
                            <el-option v-for="item in projectGenre" :key="item.value" :label="item.label"
                                       :value="item.value"></el-option>
                        </el-select>
                    </div>
                    <div class="item">
                        <div class="left">项目审核</div>
                        <el-select size="medium" v-model="project_grade" popper-class="global_select_popper"
                                   @change="search">
                            <el-option v-for="item in projectGrade" :key="item.value" :label="item.label"
                                       :value="item.value"></el-option>
                        </el-select>
                    </div>
                    <div class="item number">项目总数：{{ total }}个</div>
                    <div class="item number">总投资金额：{{ all_invest_num_total }}万元</div>
                </div>
                <addReserveProject :edit="false" v-if="chubei_project_power.power.edit"></addReserveProject>
            </div>
            <div class="table">
                <el-table :data="tableData" style="width:100%" height="100%" v-loading="loading">
                    <el-table-column prop="project_number" label="序号" align="center"></el-table-column>
                    <el-table-column prop="project_name" label="项目名称" align="center"></el-table-column>
                    <el-table-column prop="project_fuze" label="项目负责人" align="center"></el-table-column>
                    <el-table-column label="项目类别" align="center">
                        <template slot-scope="scope">
                            <span>{{ initProjectGenre(scope.row.project_genre) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="项目审核" align="center">
                        <template slot-scope="scope">
                            <span>{{ initProjectGrade(scope.row) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="项目性质" align="center">
                        <template slot-scope="scope">
                            <span>{{ initProjectQuality(scope.row.project_quality) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="总投资金额(万元)" align="center">
                        <template slot-scope="scope">
                            <span v-if="scope.row.all_invest_num">{{
                                    Number(scope.row.all_invest_num) / 10000
                                }}万元</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" align="center">
                        <template slot-scope="scope">
                            <div class="operation">
                                <el-popover placement="bottom" width="100" :open-delay="200" trigger="hover"
                                            :visible-arrow="false" popper-class="operation_popover"
                                            transition="el-zoom-in-top">
                                    <ul>
                                        <li>
                                            <addReserveProject :edit="true" :project_id="scope.row.project_id"
                                                               v-if="chubei_project_power.power.edit"></addReserveProject>
                                        </li>
                                        <li>
                                            <reserveProjectDetail
                                                    :project_id="scope.row.project_id"></reserveProjectDetail>
                                        </li>
                                        <li @click="deleteProject(scope.row.project_id)"
                                            v-if="chubei_project_power.power.edit">删除
                                        </li>
                                        <!--                                        <li @click="projectUpgrade(scope.row)"
                                                                                    v-if="scope.row.project_grade!=4&&chubei_project_power.power.up">项目升级
                                                                                </li>-->
                                        <li @click="projectUpgrade(scope.row)"
                                            v-if="scope.row.project_grade==1&&chubei_project_power.power.up">项目审核
                                        </li>
                                        <li>
                                            <div @click="upProject(scope.row)">市项目管理</div>
                                        </li>
                                    </ul>
                                    <img slot="reference" src="../../assets/img/caozuo.png" alt="">
                                </el-popover>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="pagination">
                    <el-pagination @current-change="pageChange" :current-page="page" :page-size="pagesize"
                                   layout="total, prev, pager, next, jumper" :total="total"></el-pagination>
                </div>
            </div>
        </div>
        <reserveAudit ref="reserve_audit" @initList="reserveProjectList"></reserveAudit>
    </div>
</template>

<script>
import api from '@/api/methods/index';
import addReserveProject from '@c/reserve/add_reserve_project.vue';
import reserveProjectDetail from '@c/reserve/reserve_project_detail.vue';
import reserveAudit from '@c/reserve/reserve_audit.vue';
import computedData from '@/mixins/computedData.js';
import globalYear from '@/mixins/global_year';

export default {
    components: {addReserveProject, reserveProjectDetail, reserveAudit},
    mixins: [globalYear,computedData],
    data() {
        return {
            tableData: [], page: 1, pagesize: 15, total: 0, loading: false, all_invest_num_total: 0,//所有项目总投资金额
            area: 0,
            areaList: [
                {value: 0, label: '全部(默认)'},
                {value:1, label:'州本级'},
                {value:2, label:'玛沁县'},
                {value:5, label:'甘德县'},
                {value:6, label:'达日县'},
                {value:4, label:'班玛县'},
                {value:3, label:'久治县'},
                {value:7, label:'玛多县'},
            ],
            project_quality: 0,
            projectQuality: [
                {value: 0, label: '全部(默认)'},
                {value: 1, label: '固定资产投资类'},
                {value: 2, label: '非固定资产投资类'},
                {value: 3, label: '其他'}
            ],
            project_genre: 0,
            projectGenre: [
                {value: 0, label: '全部(默认)'},
                {value: 1, label: '保障和改善民生'},
                {value: 2, label: '文化教育支援'},
                {value: 3, label: '产业支援促进就业'},
                {value: 4, label: '智力支援'},
                {value: 5, label: '交往交流交融'},
                // {value:6,label:'基层组织和政权建设'}
            ],
            project_grade: 0,
            projectGrade: [
                {value: 0, label: '全部(默认)'},
                {value: 1, label: '申报待审'},
                {value: 2, label: '不予支持'},
                {value: 3, label: '进一步完善'},
                {value: 4, label: '开展前期工作'},
            ],
        };
    },
    computed: {
        chubei_project_power() {
            return this.$store.state.user.current_user.chubei_project_power;
        },
        current_userid() {
            return this.$store.state.user.current_user.userid;
        }
    },
    created() {
        if (this.chubei_project_power.area > 0) {
            this.area = this.chubei_project_power.area;
        }
        this.reserveProjectList();
    },
    methods: {
        reserveProjectList() {
            this.loading = true;
            this.tableData = [];
            let param = {page: this.page, pagesize: this.pagesize};
            if (this.year) param.year = this.year;
            if (this.area) param.area = this.area;
            if (!this.chubei_project_power.area) {
                param.fuze_users = this.current_userid;
            }
            if (this.project_quality) param.project_quality = this.project_quality;
            if (this.project_genre) param.project_genre = this.project_genre;
            if (this.project_grade) param.project_grade = this.project_grade;
            api.reserve.reserveProjectList(param).then(res => {
                if (res.data) {
                    this.total = res.data.count || 0;
                    if (res.data.all_invest_num && !isNaN(Number(res.data.all_invest_num))) {
                        this.all_invest_num_total = Number(res.data.all_invest_num) / 10000;
                    } else {
                        this.all_invest_num_total = 0;
                    }
                    this.tableData = res.data.lists || [];
                }
                this.loading = false;
            });
        },
        search() {
            this.page = 1;
            this.reserveProjectList();
        },
        pageChange(page) {
            this.page = page;
            this.reserveProjectList();
        },
        deleteProject(id) {
            const h = this.$createElement;
            this.$msgbox({
                title: '提示',
                message: h('p', null, '确定要删除该储备项目吗?'),
                showCancelButton: true,
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                beforeClose: (action, instance, done) => {
                    if (action === 'confirm') {
                        api.reserve.deleteReserveProject({project_id: id}).then(res => {
                            if (res.data.state == 1) {
                                this.$message({
                                    message: '储备项目删除成功',
                                    type: 'success'
                                });
                                this.reserveProjectList();
                            }
                        });
                        done();
                    } else {
                        done();
                    }
                }
            }).catch(() => {

            });
        },
        //项目审核
        projectUpgrade(info) {
            this.$refs.reserve_audit.project_id = info.project_id;
            this.$refs.reserve_audit.show = true;
            // const h = this.$createElement;
            // let param = {
            //     project_id: info.project_id,
            //     project_grade: info.project_grade ? Number(info.project_grade) + 1 : 2
            // };
            // this.$msgbox({
            //     title: '提示',
            //     message: h('p', null, `项目将升级到${this.initProjectGrade(param.project_grade)}，是否确定？`),
            //     showCancelButton: true,
            //     confirmButtonText: '确定',
            //     cancelButtonText: '取消',
            //     beforeClose: (action, instance, done) => {
            //         if (action === 'confirm') {
            //             api.reserve.reserveprojectUpgrade(param).then(res => {
            //                 if (res.data.state == 1) {
            //                     this.$message({
            //                         message: '项目升级成功',
            //                         type: 'success'
            //                     });
            //                     this.reserveProjectList();
            //                 }
            //             });
            //             done();
            //         } else {
            //             done();
            //         }
            //     }
            // }).catch(() => {
            //
            // });
        },
        upProject(obj) {
            const h = this.$createElement;
            this.$msgbox({
                title: '提示',
                message: h('p', null, `确定要将该储备项目（${obj.project_name}）加入市项目管理吗?`),
                showCancelButton: true,
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                beforeClose: (action, instance, done) => {
                    if (action === 'confirm') {
                        api.reserve.toProject({project_ids: obj.project_id}).then(res => {
                            if (res.data.state == 1) {
                                this.$message({
                                    message: '加入市项目管理成功',
                                    type: 'success'
                                });
                                this.reserveProjectList();
                            }
                        });
                        done();
                    } else {
                        done();
                    }
                }
            }).catch(() => {

            });
        }
    }
};
</script>

<style scoped lang="less">
.container {
    height: 100% !important;
}

.reserve {
    .filter {
        padding-top: 10px;
        display: flex;

        .search {
            flex: 1;
            display: flex;
            flex-wrap: wrap;

            .item {
                margin-bottom: 10px;
                display: flex;
                align-items: center;
                flex: 0 0 215px;
                margin-right: 20px;

                &.btn {
                    flex: 0 0 88px;
                }

                &.total {
                    color: #34c30d;
                    font-weight: 700;
                }

                &.number {
                    color: #3396fb;
                    font-weight: 700;
                }

                .left {
                    flex: 0 0 65px;
                    padding: 0 !important;
                }

                /deep/ .el-date-editor.el-input {
                    width: 100%;
                }
            }
        }
    }
}
</style>